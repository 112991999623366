import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import HeaderAndFooterLayout from "../../../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../../../CommonPages/CommonHeader";
import Sidebar from "../AcquirerConfig/Sidebar";
import RulesTab from "./RulesTab";
import BlockEmailList from "./BlockEmailList";
import BlockCardList from "./BlockCardList";
import LoadBalance from "./LoadBalance";
import AMLRulesTab from "./AMLRulesTab";
import AcquirerTab from "./AcquirerTab";
import {
  GET_ACQUIRER_DATA,
  GET_CARD_AQUIRER_ROUTING_SERVICE_DATA,
  GET_SINGLE_CARD_AQUIRER_SERVICE,
} from "../../../../../../config/endpoints";
import Loading from "../../../../../common/Loading";
import localDb from "../../../../../../localDb";
import { call } from "../../../../../../config/axios";
const rulesIcon = "/cryptonpay/crytoicons/rules.png";
const trafficIcon = "/cryptonpay/crytoicons/traffic.png";
const loadIcon = "/cryptonpay/crytoicons/load.png";
const statusIcon = "/cryptonpay/crytoicons/status.png";
const amlIcon = "/cryptonpay/crytoicons/aml.png";
const acquirersIcon = "/cryptonpay/crytoicons/acquirers.png";

const AcquirerConfig = (props) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const [cardAquirer, setCardAquirer] = useState();
  const [singleCardRoutingData, setSingleCardRoutingData] = useState();
  // getCardAquirerRoutingServiceData,
  const imageBaseURL = useSelector((state) => state?.config?.image_base_url);
  const [value, setValue] = useState(1);
  const [acquirerServiceData, setAcquirerServiceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const { id } = useParams();

  const tabNames = [
    {
      name: "Acquirers",
      image: imageBaseURL + acquirersIcon,
      value: 1,
    },
    {
      name: "Rules",
      image: imageBaseURL + rulesIcon,
      value: 2,
    },
    {
      name: "AML Rules",
      image: imageBaseURL + amlIcon,
      value: 3,
    },
  ];
  const tabWithSubTabsNames = [
    {
      name: "Blocked Lists",
      tabValue: 1,
      image: imageBaseURL + amlIcon,
      subTabs: [
        {
          name: "Blocked Email Lists",
          image: imageBaseURL + trafficIcon,
          value: 4,
        },
        {
          name: "Blocked Card Lists",
          image: imageBaseURL + statusIcon,
          value: 5,
        },
      ],
    },
    {
      name: "Balance",
      tabValue: 2,
      image: imageBaseURL + loadIcon,
      subTabs: [
        {
          name: "Load Balancing",
          image: imageBaseURL + trafficIcon,
          value: 6,
        },
      ],
    },
  ];
  const handletoggleActiveCountryCode = async (payload) => {
    // Need API call
  };
  const onSelectTab = (val) => {
    setValue(val);
  };
  //Get all Acquirers
  const getAllAcquirers = async () => {
    setLoading(true);
    try {
      const res = await call(
        {
          ...GET_ACQUIRER_DATA,
          url: baseURL + GET_ACQUIRER_DATA.url,
        },
        {
          email: localDb.getVal("email"),
        },
      );

      if (res?.status === 200) {
        setAcquirerServiceData(res?.data);
        setLoading(false);
      }
    } catch (error) {
      // logoutAfterError(error);
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const getSingleCardRoutingServiceData = async () => {
    setLoading(true);
    const uniqueId = id;
    const payload = {
      email: localDb.getVal("email"),
      uniqueId: uniqueId,
    };
    try {
      const res = await call(
        {
          ...GET_SINGLE_CARD_AQUIRER_SERVICE,
          url: baseURL + GET_SINGLE_CARD_AQUIRER_SERVICE.url,
        },
        payload,
      );

      if (res?.status === 200) {
        setLoading(false);
        setSingleCardRoutingData(res?.data);
      }
    } catch (error) {
      // logoutAfterError(error);
      setLoading(false);
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    getAllAcquirers();
    getSingleCardRoutingServiceData();
  }, [reload, value]);
  return (
    <HeaderAndFooterLayout>
      <CommonHeader name={singleCardRoutingData?.acquirerRoutingServiceName} />
      {loading && <Loading />}
      <div className="cardAcquirerRouting container">
        <div className="flex flex-col md:flex-row">
          <div className="mb-4 mt-12 md:mb-0 md:mt-0">
            <Sidebar
              tabNames={tabNames}
              tabWithSubTabsNames={tabWithSubTabsNames}
              onSelectTab={onSelectTab}
            />
          </div>
          {value === 1 ? (
            <AcquirerTab
              acquirerServicesData={acquirerServiceData}
              serviceProviderAcquirerActivationList={
                singleCardRoutingData?.routingService?.acquirerActivation
              }
              reload={reload}
              setReload={setReload}
            />
          ) : null}
          {value === 2 ? (
            <RulesTab
              acquireService={acquirerServiceData}
              singleCardRoutingData={singleCardRoutingData}
              reload={reload}
              setReload={setReload}
            />
          ) : null}
          {value === 3 ? (
            <AMLRulesTab
              acquirerServiceData={
                singleCardRoutingData?.routingService?.amlRules
              }
              uniqueId={singleCardRoutingData?.uniqueId}
              reload={reload}
              setReload={setReload}
            />
          ) : null}
          {value === 4 ? (
            <BlockEmailList
              acquireService={singleCardRoutingData}
              uniqueId={singleCardRoutingData?.uniqueId}
              reload={reload}
              setReload={setReload}
            />
          ) : null}
          {value === 5 ? (
            <BlockCardList
              acquireService={singleCardRoutingData}
              uniqueId={singleCardRoutingData?.uniqueId}
              reload={reload}
              setReload={setReload}
            />
          ) : null}
          {value === 6 ? (
            <LoadBalance
              acquireService={singleCardRoutingData?.routingService}
              uniqueId={singleCardRoutingData?.uniqueId}
              reload={reload}
              setReload={setReload}
            />
          ) : null}
        </div>
      </div>
    </HeaderAndFooterLayout>
  );
};

export default AcquirerConfig;
