import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IOSSwitch from "../AcquirerConfig/IOSSwitch";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import localDb from "../../../../../../localDb";
import { call } from "../../../../../../config/axios";
import { TOGGLE_CARD_AQUIRER_ACTIVATION } from "../../../../../../config/endpoints";
import Loading from "../../../../../common/Loading";

const AcquirerTab = ({
  acquirerServicesData,
  serviceProviderAcquirerActivationList,
  reload,
  setReload,
}) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [acquirerServicesData1, setAcquirerServicesData1] = useState();
  const activatedList = acquirerServicesData?.filter((elem) =>
    serviceProviderAcquirerActivationList?.find(
      ({ acquirerUniqueId }) => elem?.acquirerUniqueId === acquirerUniqueId,
    ),
  );
  const acquirerServicesDatanew = acquirerServicesData?.map((item) =>
    activatedList?.find(({ acquirerUniqueId }) => item?.acquirerUniqueId === acquirerUniqueId)
      ? { ...item, isAcquireServiceActive: true }
      : { ...item, isAcquireServiceActive: false },
  );
  setTimeout(() => {
    setLoading(false)
    setAcquirerServicesData1(acquirerServicesDatanew);
  }, 1000);

  const handletoggleActiveAquirerService = async (payload) => {
    setLoading(true);
    const data = {
      ...payload,
      email: localDb.getVal("email"),
    };
    try {
      const res = await call(
        {
          ...TOGGLE_CARD_AQUIRER_ACTIVATION,
          url: baseURL + TOGGLE_CARD_AQUIRER_ACTIVATION.url,
        },
        data,
      );

      if (res?.status === 200) {
        setLoading(false);
        setReload(!reload);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const onClickSwitch = (e, item) => {
    const payload = {
      isActivation: !e.target.checked ? false : true,
      acquirerUniqueId: item?.acquirerUniqueId,
      uniqueId: id,
    };
    handletoggleActiveAquirerService(payload);
  };
  return (
    <div className="TabContainer">
      <div className="h-full bg-[#F9FAFB]">
        <h2 className="heading">Acquirers</h2>
        {loading && <Loading/>}
        <div>
          {acquirerServicesData1?.map((item, i) => {
            return (
              <div key={i} className=" ml-[10px] border-b">
                <div className="flex w-full max-w-[600px] px-2  py-4  ">
                  <div className="flex-1 ">
                    {item?.acquirerSetting?.acquirerName}
                  </div>
                  <div className="flex-1">
                    <IOSSwitch
                      defaultChecked={item?.isAcquireServiceActive}
                      className={`${isViewPermissionValid ? "disabled" : ""}`}
                      onClick={(e) =>
                        isViewPermissionValid ? null : onClickSwitch(e, item)
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AcquirerTab;
