const LOGIN = '/restapi/admin-login';
const GET_TRANSACTIONS = '/restapi/get-transactions';
const GET_MERCHANTS = '/restapi/get-merchants';
const GET_ALL_MERCHANTS_LIST = '/restapi/get-all-merchants';
const GET_GLOBAL_SETTING = '/restapi/get-globalSettings';
const GET_WHITELIST_IPS = '/restapi/get-whiteListIPs';
const GET_All_FEATURE_CONFIG = '/restapi/get-all-feature-config';
const UPDATE_FEATURE_CONFIG = '/restapi/update-feature-config';
const UPDATE_GLOBAL_SETTING = '/restapi/update-globalSettings';
const UPDATE_WHITELIST_IP = '/restapi/update-whiteListIP';
const UPDATE_CRYPTO = '/restapi/update-crypto';
const CREATE_GLOBAL_SETTING = '/restapi/create-globalSettings';
const CREATE_WHITELIST_IP = '/restapi/create-whiteListIP';
const CREATE_FRAUD_SERVICE = '/restapi/create-card-aquire-service';
const GET_FRAUD_SERVICE = '/restapi/get-card-aquire-service';
const UPDATE_FRAUD_SERVICE = '/restapi/update-card-aquire-service';
const DELETE_FRAUD_SERVICE = '/restapi/delete-card-aquire-service';
const CREATE_CRYPTO = '/restapi/create-crypto';
const DELETE_GLOBAL_SETTING = '/restapi/delete-globalSettings';
const DELETE_WHITELIST_IP = '/restapi/delete-whiteListIP';
const DELETE_CRYPTO = '/restapi/delete-crypto';
const CREATE_MERCHANT = '/restapi/create-merchant';
const CREATE_CLIENT = '/restapi/create-client';
const GET_MERCHANT_WITH_EMAIL = '/restapi/get-merchant-with-email';
const GET_CRYPTOS = '/restapi/get-cryptos';
const GET_TRANSACTION_STATUS_CODES = '/restapi/get-transaction-status-codes';
const GET_CRYPTOS_DETAILS = '/restapi/get-cryptos-details';
const GET_WALLETS = '/restapi/get-wallets';
const GET_CENTRALISED_WALLET = '/restapi/get-centralisedwallets'
const UPDATE_CENTRALISED_WALLET_BALANCE = '/restapi/update-centralised-wallet-balance'
const VIEW_SINGLE_TRANSACTION = '/restapi/view-transaction';
const VIEW_SINGLE_WALLET = '/restapi/view-wallet';
const VIEW_SINGLE_CENTRALISED_WALLET = '/restapi/view-centralised-wallet'
const UPDATE_CENTRALISED_WALLET_STATUS = '/restapi/update-centralised-wallets-status'
const GET_TRANSFER_WALLETS = '/restapi/get-transfer-wallets';
const GET_MERCHANT_SETTING = '/restapi/get-merchant-settings';
const UPDATE_MERCHANT_SETTINGS = '/restapi/update-merchant-settings';
const UPDATE_WALLET_STATUS = '/restapi/update-wallets-status';
const GET_ERROR_LOGS = '/restapi/get-error-logs';
const SAVE_TRANSACTION_FROM_ADMIN = '/restapi/create-transaction-from-admin';
const GET_SINGLE_TRANSFER_WALLET = '/restapi/get-single-transfer-wallet';
const GET_CRYPTO_AMOUNT_EXCHANGE_RATE = '/restapi/get-crypto-or-amount-exchange-rate';
const UPDATE_WALLET_BALANCE = '/restapi/update-wallet-balance';
const UPDATE_MERCHNAT_WALLET_BALANCE = '/restapi/update-merchant-wallet-balance';
const CREATE_DEPOSIT_TO_WALLET_ADMIN = '/restapi/create-deposit-to-wallet-admin';
const GET_B2C_USER_WALLETS = '/restapi/get-all-b2c-user-wallets';
const UPDATE_B2C_USER_WALLET_BALANCE = '/restapi/b2c-user-wallet-update';
const GET_WALLET_BY_ADDRESS = "/restapi/get-wallet-by-address";

const UPDATE_TRANSACTION = '/restapi/update-transactions';
const ADD_NOTES = '/restapi/create-notes-transaction';
const UPDATE_NOTES = '/restapi/update-notes-transaction';
const UPDATE_MERCHANT_TRANSACTION = '/restapi/update-merchant-transactions';

const GET_SINGLE_CRYPTO_EXCHANGE_RATE = '/pga/GetSingleCryptoExchangeRate';
const GET_ALL_B2C_USERS = "/restapi/get-all-b2c-users";
const CREATE_VIP_LINK = "/restapi/createMerchantViplinks";
const DELETE_B2C_USER = "/restapi/delet-b2c-user";
const GET_B2C_USER_DETAILS = "/restapi/get-b2c-user-details";
const GET_B2C_WALLET_BALANCES = "/restapi/get-user-wallet-balances";
const VIEW_B2C_SINGLE_USER_WALLET = '/restapi/view-b2c-user-wallet';
const GET_B2C_USER_TRANSACTIONS = '/restapi/get-b2c-user-transactions'

const GET_ALL_BLOCKCHAIN_TRANSACTIONS = "/restapi/get-blockchain-transactions";
const GET_ALL_BLOCKCHAIN_WALLET_BALANCES = "/restapi/get-blockchain-wallet-balances";

const GET_ALL_HOUSE_KEEPING_ALERTS = "/restapi/get-housekeeping-alerts";

const SEND_MERCHANT_WEBHOOK_NOTIFICATION_FROM_ADMIN = "/restapi/send-merchant-webhook-notification-from-admin";
const GET_TRANSACTIONS_SUMMARY = "/restapi/summary-of-transactions";
const GET_SINGLE_B2C_USER_TRANSACTIONS = "/restapi/get-single-b2c-user-transactions";
const GET_WALLETS_SUMMARY = '/restapi/get-wallet-summary';
const GET_CENTRELISED_WALLETS_SUMMARY = '/restapi/get-centralisedwallets-summary';

const GET_REAL_TIME_EXCHANGE_RATES = "/restapi/real-time-exchanges-rates";
const GET_ALL_DAILY_EXCHANGE_RATES = "/restapi/daily-exchanges-rates";

const ADD_USER_NOTES = "/restapi/b2c-add-user-note";

const GET_B2C_USER_GLOBAL_SETTING_WALLETS = "/restapi/b2c-user-get-globalSettings";
const CREATE_B2C_USER_GLOBAL_SETTING_WALLET = "/restapi/b2c-user-create-globalSettings";
const UPDATE_B2C_USER_GLOBAL_SETTINGS_WALLET = "/restapi/b2c-user-update-globalSettings";
const DELETE_B2C_USER_GLOBAL_SETTING_WALLET = "/restapi/b2c-user-delete-globalSettings";

const CHANGE_ADMIN_PASSWORD = '/restapi/change-admin-password';
const MERCHANT_IMAGE_UPLOAD_SIGNED_URL = "/restapi/get-presigned-url";

const GET_B2C_SPREAD_LEVEL = "/restapi/b2c-user-get-spread-levels";
const UPDATE_B2C_SPREAD_LEVEL = "/restapi/b2c-user-update-spread-levels";
const DELETE_B2C_SPREAD_LEVEL = "/restapi/b2c-user-delete-spread-levels";
const CREATE_B2C_SPREAD_LEVEL = "/restapi/b2c-user-create-spread-levels";

const GET_B2C_RISK_LEVEL = "/restapi/b2c-user-get-risk-levels";
const UPDATE_B2C_RISK_LEVEL = "/restapi/b2c-user-update-risk-levels";
const DELETE_B2C_RISK_LEVEL = "/restapi/b2c-user-delete-risk-levels";
const CREATE_B2C_RISK_LEVEL = "/restapi/b2c-user-create-risk-levels";

const USER_SUMMARY = "/restapi/b2c-user-summary";
const UPDATE_B2C_USER_SPREAD_LEVEL = "/restapi/update-b2c-user-spread-level";
const UPDATE_B2C_USER_RISK_LEVEL = "/restapi/update-b2c-user-risk-level";
const UPDATE_B2C_USER = "/restapi/update-b2c-user-details";
const ADD_UPDATE_USER_SPREAD_VALUE = '/restapi/b2c-user-create-spread-level-data';
const GET_ALL_SPREAD_VALUE_USING_SPREAD_SETTING = '/restapi/b2c-user-get-spread-level-data';

const GET_USER_TRANSFER_WALLETS = '/restapi/b2c-user-transafer-wallets';
const CREATE_TRANSFER_WALLET = '/restapi/b2c-user-create-transfer-wallet';
const UPDATE_TRANSFER_WALLET = "/restapi/b2c-user-update-transfer-wallet";
const DELETE_TRANSFER_WALLET = "/restapi/b2c-user-delete-transfer-wallet";
const GET_MINIMUM_CONVERSION_AMOUNT = "/restapi/b2c-user-get-minimum-conversion-amount";
const CREATE_MINIMUM_CONVERSION_AMOUNT = "/restapi/b2c-user-create-minimum-conversion-amount";
const GET_RISK_LIVEL_LIMITS = "/restapi/b2c-user-get-risk-level-limits";
const UPDATE_RISK_LIVEL_LIMITS = "/restapi/b2c-user-create-risk-levels-limits";
const MAKE_WEBHOOKS_CALL = "/restapi/make-webhook-call"
const MERCHANT_TRANSACTIONS = "/restapi/get-merchant-transactions"
const GET_MERCHANT_USERS = "/restapi/get-merchant-users"
const GET_INDIVIDUAL_MERCHANT_USER_DATA = "/restapi/get-merchant-user"
const UPDATE_INDIVIDUAL_MERCHANT_USER_DATA = "/restapi/update-merchant-user"
const UPDATE_VIEW_ONLY_SETTING = "/restapi/update-view-only-setting"
const CREATE_NEW_MERCHANT_USER = "/restapi/create-new-merchant-user"
const DELETE_MERCHANT_USER = "/restapi/delete-merchant-user"
const GET_COUNTRY_DETAILS = '/restapi/get-countrydetails'
const VIEW_SINGLE_MERCHANT_TRANSACTION = '/restapi/get-single-merchant-transactions'
const GET_MERCHANT_TRANSACTIONS_SUMMARY = "/restapi/summary-of-merchant-transactions";
const GET_ALL_MERCHANT_SPREAD = "/restapi/get-merchant-spread-level";
const CREATE_MERCHANT_SPREAD = "/restapi/create-merchant-spread-level"
const GET_ALL_SEND_RECEIVE = "/restapi/get-send-receive-fees";
const UPDATE_SEND_RECEIVE = "/restapi/update-send-receive-fees";
const GET_MERCHANT_WALLET_AND_BALANCES = "/restapi/get-merchant-wallet-balances";
const CRYPTO_AMOUNT_SWAP = "/restapi/get-merchant-crypto-or-amount-swap";
const CREATE_MERCHANT_SWAP_TRANSACTION = "/restapi/create-merchant-swap-transaction"
const CONFIRM_MERCHANT_SWAP_TRANSACTION = "/restapi/confirm-merchant-swap-transaction"

const FETCH_GAS = "/restapi/fetch-gas";
const GET_MERCHANT_BALANCE = "/restapi/get-merchant-balance-by-crypto";
const CREATE_MERCHANT_WITHDRAW = "/restapi/create-merchant-withdraw-transaction";
const GET_TRANSACTION_DETAILS = '/pga/GetTransactionStatus'
const GET_MERCHANT_WALLETS = '/restapi/get-merchant-wallets-list';
const VIEW_SINGLE_MERCHANT_WALLET = '/restapi/view-merchant-wallet';
const UPDATE_MERCHANT_WALLET_STATUS = '/restapi/update-merchant-wallets-status';
const UPDATE_MERCHANT_WALLET_BALANCE = '/restapi/update-merchant-wallet-balance';
const GET_MERCHANT_WALLETS_SUMMARY = '/restapi/get-merchant-wallet-summary';
const ADMIN_DEPOSIT_WITHDRAW = '/restapi/create-admin-deposit-withdraw-transaction'

const GET_MERCHANT_AUTOMATION = '/restapi/get-merchant-automation';
const GET_ALL_PAYMENT_PROVIDERS = '/restapi/get-all-payment-providers';
const GET_PAYMENT_PROVIDER = '/restapi/get-payment-provider';
const CREATE_PAYMENT_PROVIDER = '/restapi/create-payment-provider';
const UPDATE_PAYMENT_PROVIDER = '/restapi/update-payment-provider';
const SAVE_PAYMENT_PROVIDER_DOMAIN = '/restapi/save-payment-provider-domain';
const GET_ALL_PAYMENT_SPREAD = '/restapi/get-payment-spread-level';
const CREATE_PAYMENT_SPREAD = '/restapi/create-payment-spread-level';
const GET_PAYMENT_PROVIDER_SETTINGS = '/restapi/get-payment-provider-settings';
const UPDATE_PAYMENT_PROVIDER_SETTINGS = '/restapi/update-payment-provider-settings';

const CREATE_CARD_COUNTRY_FEES='/restapi/create-card-country-settings';
const GET_CARD_COUNTRY_FEES='/restapi/get-card-country-fees';
const UPDATE_CARD_COUNTRY_FEES='/restapi/update-card-country-fees';
const CREATE_UPDATE_MERCHANT_WALLET_AUTOMATION = '/restapi/create-update-merchnat-wallet-automation';
const GET_DASHBOARD_HEADER = '/restapi/get-transactions-dashboards';
const GET_SPREAD_RATE_CRYPTO = '/restapi/get-spread-rate-crypto'
const GET_DASHBOARD_CARD_HEADER = '/restapi/get-transactions-card-dashboards';
const GET_DASHBOARD_CARD_HEADER_USER = '/restapi/get-transactions-user-count'
const GET_DASHBOARD_USER_COUNT = '/restapi/get-unique-usersCount'
const GET_BROWSER = '/restapi/get-browser-usage';
const GET_LANGUAGE = '/restapi/get-language-usage';
const GET_DEVICE = '/restapi/get-device-usage';
const GET_KYT = '/restapi/get-kyt';
const GET_DEPOSIT_WITHDRAWAL_STATISTICS = '/restapi/get-deposit-withdrawal-statistice'
const GET_UNIQUE_MERCHANT = '/restapi/get-unique-merchant-user'
const GET_SWEEP_LOGS = '/restapi/get-sweep-logs';
const GET_DEPOSIT_HASH = '/restapi/get-depositHash';
const GET_GETCOUNT_TABLE = '/restapi/get-countTable'
const GET_MERCHANT_TRANSACTIONS_COUNT_DASHBOARD = '/restapi/get-merchant-transactions-card-dashboards'
const GET_SINGLE_AQUIRER_SERVICE = '/restapi/get-single-card-aquire-service'
const UPDATE_CODE = '/restapi/update-acquire-codes'
const ADD_NEW_BLOCKED_CARD = '/restapi/add-aml-blocked-card'
const ADD_NEW_BLOCKED_EMAIL = '/restapi/add-aml-blocked-email'
const DELETE_BLOCKED_CARD = '/restapi/delete-blocked-card'
const DELETE_BLOCKED_EMAIL = '/restapi/delete-blocked-email'
const DELETE_CODE = '/restapi/delete-acquire-codes'
const UPDATE_AQUIRE_NAME = '/restapi/update-routing-name'
const UPDATE_SERVICE_NAME = '/restapi/update-routing-service-name'
const ADD_INTEGRATION_TYPE = "/restapi/create-integration-type"
const GET_INTEGRATION_TYPE = '/restapi/get-all-integration-types'
const DELETE_INTEGRATION_TYPE = '/restapi/delete-integration-type'
const EDIT_INTEGRATION_TYPE = '/restapi/update-integration-type'
const TOGGLE_AQUIRER_SERVICE = '/restapi/toggle-routing-service'
const GET_HOUSEKEEPING_ALERT = '/restapi/get-housekeeping-alerts-count'
const GET_CARD_ACQUIRE_ROUTING = '/restapi/get-card-aquire-service'
const GET_AUDIT_TRAILS = '/restapi/get-audit-trails'
const GET_TRANSACTION_CURRENT_STATUS = '/restapi/get-transaction-current-status'
const GET_ALL_CLIENTS = '/restapi/get-clients';
const CREATE_CLIENT_TRANS_CONFIG = '/restapi/create-client-trans-config';
const GET_ALL_CLIENT_TRANS_CONFIG = '/restapi/get-all-client-trans-config';
const UPDATE_CLIENT_TRANS_CONFIG = '/restapi/update-client-trans-config';
const DELETE_CLIENT_TRANS_CONFIG = '/restapi/delete-client-trans-config';
const CREATE_CLIENT_TRANSACTION = '/restapi/create-client-transaction';
const GET_CLIENT_TRANSACTIONS = '/restapi/get-client-transactions';
const GET_CLIENT_BALANCES = '/restapi/get-client-balances';
const GET_PAYMENTS_SPREAD_RATES = "/restapi/get-payment-spreadrates";
const GET_ALL_CLIENT_TRANSACTIONS = '/restapi/get-all-client-transactions';
const GET_SINGLE_TRANSACTION_OF_CLIENT = '/restapi/get-single-transaction-of-client';
const GET_FIAT_EXCHANGE_RATE = '/restapi/get-fiat-exchange-rate';
const GET_CLIENT_BY_CLINENTID = '/restapi/get-single-client-data'
const UPDATE_SINGLE_CLIENT = '/restapi/update-single-client-data';
const ADMIN_FORGOT_PASSWORD = '/restapi/admin-forgot-password';
const ADMIN_NEW_PASSWORD = '/restapi/admin-new-password';
const GET_PAYMENT_PROVIDER_SWEEP_CONFIG = '/restapi/get-payment-provider-config'
const CREATE_PAYMENT_PROVIDER_SWEEP_CONFIG = '/restapi/create-payment-provider-config'
const UPDATE_PAYMENT_PROVIDER_SWEEP_CONFIG = '/restapi/update-payment-provider-config'
const GET_CLIENT_PRESIGNED_URL = '/restapi/get-client-presigned-url';
const CLIENT_FEE_SETTINGS = '/restapi/save-client-fee-setting';
const GET_CLIENT_FEE_SETTINGS = '/restapi/get-client-fee-setting';
const UPDATE_CLIENT_TRANSACTION_MERCHANT = '/restapi/update-client-transaction-merchant';
const GET_CLIENT_TRANSACTIONS_DASHBOARD= '/restapi/get-client-transaction-dashboard';
const GET_CLIENT_TRANSACTIONS_SUMMARY = "/restapi/get-client-transaction-summary";
const UPDATE_CLIENT_SETTINGS = "/restapi/update-client-settings";
const DELETE_PAYMENT_PROVIDER_DOMAIN = "/restapi/delete-payment-provider-domain";
const CREATE_PAYMENT_PROVIDER_USER = "/restapi/create-payment-provider-user";
const GET_PAYMENT_PROVIDER_USER_LIST = "/restapi/get-all-payment-provider-users";
const DELETE_PAYMENT_PROVIDER_USER = "/restapi/delete-payment-provider-user";
const UPDATE_PAYMENT_PROVIDER_USER = "/restapi/update-payment-provider-user";
const WHITE_LEBAL_PAYMENT_PROVIDER = '/restapi/get-payment-provider-for-domain'
const CREATE_CLIENT_TRANSACTION_MERCHANT = '/restapi/create-client-transaction-merchant';
const GET_COUNTRY_CODE_FORM_DB = "/restapi/get-country-code"
const ADMIN_USER_VERIFY_2FA_TOKEN = "/restapi/admin-user-verify-2fa-token"
const ADMIN_GENERATE_QR_CODE = "/restapi/genrateQrCode";
const FETCH_STATEMENT_API = '/restapi/get-admin-client-statement';
const UPDATE_STATEMENT_API = '/restapi/update-admin-client-statement';
const CREATE_STATEMENT_API = '/restapi/generate-admin-client-statement';
const GENERATE_STATEMENT_PDF = '/restapi/generate-statement-pdf';
const SAVE_MERCHANT_WHITELIST_CARDS = "/restapi/save-merchant-whitelist-cards"
const GET_MERCHANT_WHITELIST_CARDS_LISTS ="/restapi/getMerchantsWhiteListCards"
const GET_CLIENTS_ASSOCIATED_USER = "/restapi/get-client-associated-user"
const UPDATE_CLIENTS_ASSOCIATED_USER = "/restapi/update-client-associated-user"
const CREATE_CLIENTS_ASSOCIATED_USER = "/restapi/create-client-associated-user"
const SAVE_PAYMENT_PROVIDER_WHITELIST_CARDS = "/restapi/save-payment-provider-whitelist-cards"
const GET_PAYMENT_PROVIDER_WHITELIST = "/restapi/get-payment-provider-whitelist-cards"

const CREATE_MAX_TRAFFIC = "/restapi/create-max-traffic"
const TOGGLE_LOAD_BALANCING = "/restapi/toggle-load-balancing"
const GET_MAX_TRAFFIC_BY_ACQUIRER = "/restapi/get-max-traffic-by-acquirer"
const GET_MAX_TRAFFIC_STATUS = "/restapi/get-max-traffic-status"
const RESET_MAX_TRAFFIC_STATUS = "/restapi/reset-max-traffic-status"

const GET_SUCCESS_ROUTING_BY_ACQUIRER = "/restapi/get-success-routing-by-acquirer"
const GET_PERIOD_LIST_BY_ACQUIRER = "/restapi/get-period-list-by-acquirer"

const GET_LEAST_COST_ROUTING_BY_ACQUIRER = "/restapi/get-least-cost-routing-by-acquirer"
const UPDATE_LEAST_COST_ROUTING_BY_ACQUIRER = "/restapi/update-least-cost-routing-by-acquirer"

const GET_WEIGHTING_BY_ACQUIRER = "/restapi/get-weighting-by-acquirer"
const UPDATE_WEIGHTING_BY_ACQUIRER = "/restapi/update-weighting-by-acquirer"
const UPDATE_CUSTOMCITY ="/restapi/edit-customCity"
const DELETE_CUSTOMCITY ="/restapi/delete-customCity"


//Dashboard card
const GET_FILTER_OPTIONS_CARD_DASHBOARD = "/restapi/card-dashboard-filters"
const GET_GRAPH_CARD_DASHBOARD_DATA = "/restapi/card-dashboard-graphData"
const GET_CARD_TRANSACTIONS_SUMMARY = "/restapi/card-dashboard-summary"
const GET_ERROR_DETAILS_CARD = "/restapi/card-dashboard-transactions-errorDetails"
const GET_CARD_TRANSACTION_DASHBOARD = '/restapi/card-dashboard-transactions'

const GET_RULES_BY_ACQUIRER_FOR_SPLITTRAFFIC = "/restapi/get-rules-by-acquirer-for-splitTraffic"
const UPDATE_SPLITTRAFFIC_BY_ACQUIRER = "/restapi/update-splitTraffic-by-acquier"

const GET_TRAFFIC_MASKEDPAN_WHITELIST = "/restapi/get-traffic-maskedpan-whitelist"

const SAVE_MERCHANT_EMAIL_WHITELIST = "/restapi/save-email-merchant-whitelist"
const GET_MERCHANT_EMAIL_WHITELIST = "/restapi/get-email-merchant-whitelist"
const UPLOAD_MERCHANT_IMAGE = "/restapi/upload-merchant-profile-image"

const GET_ACQUIRER_DATA = "/restapi/get-acquirer-data";
const CREATE_NEW_ACQUIRER = "/restapi/create-new-acquirer";
const UPDATE_SINGLE_ACQUIRER = "/restapi/update-single-acquirer";
const DELETE_SINGLE_ACQUIRER = "/restapi/delete-single-acquirer";
const GET_SPLIT_TRAFFIC_FTD_ACTIVATION = "/restapi/get-split-traffic-ftd-activation";
const SPLIT_TRAFFIC_FTD_ACTIVATION = "/restapi/split-traffic-ftd-activation";
// Rules Tab New
const TOGGLE_BLOCKED_BIN_COUNTRIES_ACTIVATION =
  "/restapi/toggle-blocked-bin-countries-activation";
const ACQUIRER_RULES_UPDATE_BLOCKED_BIN_COUNTRIES =
  "/restapi/acquirer-rules-update-blocked-bin-countries";
const ACQUIRER_RULES_UPDATE_BLOCKED_USER_COUNTRIES =
  "/restapi/acquirer-rules-update-blocked-user-countries";
const TOGGLE_BLOCKED_USER_COUNTRIES_ACTIVATION =
  "/restapi/toggle-blocked-user-countries-activation";
const ACQUIRER_RULES_UPDATE_BLOCKED_CARD_SCHEMES =
  "/restapi/acquirer-rules-update-blocked-card-schemes";
const TOGGLE_BLOCKED_CARD_SCHEMES_ACTIVATION =
  "/restapi/toggle-blocked-card-schemes-activation";
const ACQUIRER_RULES_UPDATE_ALLOWED_CURRENCIES =
  "/restapi/acquirer-rules-update-allowed-currencies";
const TOGGLE_ALLOWED_CURRENCIES_ACTIVATION =
  "/restapi/toggle-allowed-currencies-activation";
const ACQUIRER_RULES_UPDATE_IP_ADRESSES =
  "/restapi/acquirer-rules-update-ip-adresses";
const TOGGLE_ALLOWED_IP_ADDRESS_ACTIVATION =
  "/restapi/acquirer-rules-update-ip-adresses";
const ACQUIRER_RULES_UPDATE_MANDATORY_FIELDS =
  "/restapi/acquirer-rules-update-mandatory-fields";
const TOGGLE_MANDATORY_FIELDS_ACTIVATION =
  "/restapi/toggle-mandatory-fields-activation";
  // Balance Tab
const UPDATE_MAXIMUM_TRAFFIC_SETTING =
  "/restapi/update-maximum-traffic-setting";
const GET_MAXIMUM_TRAFFIC_SETTING = "/restapi/get-maximum-traffic-setting";
const UPDATE_WEIGHTING_PERCENTAGE = "/restapi/update-weighting-percentage";
const UPDATE_LEAST_COST_ROUTING = "/restapi/update-least-cost-routing";
const GET_LEAST_COST_ROUTING = "/restapi/get-least-cost-routing";
const GET_WEIGHTING_PERCENTAGE = "/restapi/get-weighting-percentage";
const GET_GLOBAL_TRANSACTION_STATUS = "/restapi/get-global-transaction-status";
const GET_GLOBAL_SUCCESS_ROUTING_BY_ACQUIRER =
  "/restapi/get-global-success-routing-by-acquirer";
const GET_GLOBAL_PERIOD_LIST_BY_ACQUIRER =
  "/restapi/get-global-period-list-by-acquirer";
const TOGGLE_CARD_AQUIRER_ACTIVATION =
  "/restapi/toggle-card-aquirer-activation";
const GET_CARD_AQUIRER_ROUTING_SERVICE_DATA =
  "/restapi/get-card-aquirer-routing-service-data";
const GET_SINGLE_CARD_AQUIRER_SERVICE =
  "/restapi/get-single-card-aquirer-service";
const GET_CARD_AQUIRER_SERVICES = "/restapi/get-card-aquirer-services";
const CREATE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW =
  "/restapi/create-single-card-aquirer-routing-service-new";
const UPDATE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW =
  "/restapi/update-single-card-aquirer-routing-service-new";
const DELETE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW =
  "/restapi/delete-single-card-aquirer-routing-service-new";
const TOGGLE__BLOCKED__COUNTRIES__ACTIVATION =
  "/restapi/toggle-blocked-countries-activation";
const UPDATE_BLOCKED_COUNTRY_BY_SERVICE_LIST =
  "/restapi/update-blocked-country-by-service-list";
const GET_BLOCKED_COUNTRY_BY_SERVICE_LIST =
  "/restapi/get-blocked-country-by-service-list";
const UPDATE_AML_RULES = "/restapi/update-aml-rules";
const GET_AML_RULES = "/restapi/get-aml-rules";
const GET_LOAD_BALANCING = "/restapi/get-load-balancing";
const UPDATE_LOAD_BALANCING = "/restapi/update-load-balancing";
const ADD_BLOCKED_CARD = "/restapi/add-blocked-card";
const DELETE_BLOCKED_CARD_NEW = "/restapi/delete-blocked-card-new";
const ADD_BLOCKED_EMAIL = "/restapi/add-blocked-email";
const DELETE_BLOCKED_EMAIL_NEW = "/restapi/delete-blocked-email-new";
const GET_BLOCKED_CARD_LIST = "/restapi/get-blocked-card-list";
const GET_BLOCKED_EMAIL_LIST = "/restapi/get-blocked-email-list";
const POST = 'POST';
// const DELETE = 'DELETE';
// const GET = 'GET';
// const PUT = 'PUT'

exports.LOGIN = {
  url: LOGIN,
  method: POST,
};

exports.UPDATE_CLIENTS_ASSOCIATED_USER = {
  url: UPDATE_CLIENTS_ASSOCIATED_USER,
  method: POST
}


exports.CREATE_CLIENTS_ASSOCIATED_USER = {
  url: CREATE_CLIENTS_ASSOCIATED_USER,
  method: POST
}

exports.GET_CLIENTS_ASSOCIATED_USER = {
  url: GET_CLIENTS_ASSOCIATED_USER,
  method: POST
}

exports.SAVE_MERCHANT_WHITELIST_CARDS = {
  url: SAVE_MERCHANT_WHITELIST_CARDS,
  method: POST
}
exports.GET_MERCHANT_WHITELIST_CARDS_LISTS = {
  url: GET_MERCHANT_WHITELIST_CARDS_LISTS,
  method: POST
}
exports.SAVE_MERCHANT_EMAIL_WHITELIST = {
  url: SAVE_MERCHANT_EMAIL_WHITELIST,
  method: POST
}
exports.GET_MERCHANT_EMAIL_WHITELIST = {
  url: GET_MERCHANT_EMAIL_WHITELIST,
  method: POST
}

exports.SAVE_PAYMENT_PROVIDER_WHITELIST_CARDS = {
  url : SAVE_PAYMENT_PROVIDER_WHITELIST_CARDS,
  method: POST
};
exports.GET_PAYMENT_PROVIDER_WHITELIST={
  url : GET_PAYMENT_PROVIDER_WHITELIST,
  method:POST
}
exports.GENERATE_STATEMENT_PDF = {
  url: GENERATE_STATEMENT_PDF,
  method: POST,
};

exports.FETCH_STATEMENT_API = {
  url: FETCH_STATEMENT_API,
  method: POST,
};
exports.UPDATE_STATEMENT_API = {
  url: UPDATE_STATEMENT_API,
  method: POST,
};
exports.CREATE_STATEMENT_API = {
  url: CREATE_STATEMENT_API,
  method: POST,
};
exports.CREATE_CLIENT_TRANSACTION_MERCHANT = {
  url: CREATE_CLIENT_TRANSACTION_MERCHANT,
  method: POST,
};
exports.GET_CLIENT_FEE_SETTINGS = {
  url: GET_CLIENT_FEE_SETTINGS,
  method: POST,
};

exports.UPDATE_CLIENT_SETTINGS = {
  url: UPDATE_CLIENT_SETTINGS,
  method: POST,
};
exports.GET_CLIENT_TRANSACTIONS_SUMMARY = {
  url: GET_CLIENT_TRANSACTIONS_SUMMARY,
  method: POST,
};
exports.GET_CLIENT_TRANSACTIONS_DASHBOARD = {
  url: GET_CLIENT_TRANSACTIONS_DASHBOARD,
  method: POST,
};
exports.UPDATE_CLIENT_TRANSACTION_MERCHANT = {
  url: UPDATE_CLIENT_TRANSACTION_MERCHANT,
  method: POST,
};
exports.CLIENT_FEE_SETTINGS = {
  url: CLIENT_FEE_SETTINGS,
  method: POST,
};
exports.GET_CLIENT_PRESIGNED_URL = {
  url: GET_CLIENT_PRESIGNED_URL,
  method: POST,
};
exports.GET_PAYMENTS_SPREAD_RATES = {
  url: GET_PAYMENTS_SPREAD_RATES,
  method: POST,
};
exports.ADMIN_FORGOT_PASSWORD = {
  url: ADMIN_FORGOT_PASSWORD,
  method: POST,
};

exports.ADMIN_NEW_PASSWORD = {
  url: ADMIN_NEW_PASSWORD,
  method: POST,
};
exports.GET_FIAT_EXCHANGE_RATE = {
  url: GET_FIAT_EXCHANGE_RATE,
  method: POST,
};
exports.GET_CLIENT_BY_CLINENTID = {
  url: GET_CLIENT_BY_CLINENTID,
  method: POST,
};
exports.UPDATE_SINGLE_CLIENT = {
  url: UPDATE_SINGLE_CLIENT,
  method: POST,
};

exports.GET_TRANSACTIONS = {
  url: GET_TRANSACTIONS,
  method: POST,
};
exports.GET_TRANSACTION_CURRENT_STATUS = {
  url: GET_TRANSACTION_CURRENT_STATUS,
  method: POST,
};

exports.GET_WALLET_BY_ADDRESS = {
  url: GET_WALLET_BY_ADDRESS,
  method: POST,
};

exports.GET_MERCHANT_AUTOMATION = {
  url: GET_MERCHANT_AUTOMATION,
  method: POST,
};

exports.GET_MERCHANTS = {
  url: GET_MERCHANTS,
  method: POST,
};

exports.GET_GLOBAL_SETTING = {
  url: GET_GLOBAL_SETTING,
  method: POST,
};

exports.GET_WHITELIST_IPS = {
  url: GET_WHITELIST_IPS,
  method: POST,
};

exports.GET_All_FEATURE_CONFIG = {
  url: GET_All_FEATURE_CONFIG,
  method: POST,
};

exports.UPDATE_FEATURE_CONFIG = {
  url: UPDATE_FEATURE_CONFIG,
  method: POST,
};

exports.GET_ALL_MERCHANTS_LIST = {
  url: GET_ALL_MERCHANTS_LIST,
  method: POST,
};

exports.DELETE_WHITELIST_IP = {
  url: DELETE_WHITELIST_IP,
  method: POST,
};

exports.DELETE_CRYPTO = {
  url: DELETE_CRYPTO,
  method: POST,
};

exports.DELETE_GLOBAL_SETTING = {
  url: DELETE_GLOBAL_SETTING,
  method: POST,
};

exports.CREATE_MERCHANT = {
  url: CREATE_MERCHANT,
  method: POST,
};

exports.CREATE_CLIENT = {
  url: CREATE_CLIENT,
  method: POST,
};

exports.GET_MERCHANT_WITH_EMAIL = {
  url: GET_MERCHANT_WITH_EMAIL,
  method: POST,
}
exports.GET_CRYPTOS = {
  url: GET_CRYPTOS,
  method: POST,
};

exports.GET_TRANSACTION_STATUS_CODES = {
  url: GET_TRANSACTION_STATUS_CODES,
  method: POST,
};

exports.GET_DASHBOARD_HEADER = {
  url: GET_DASHBOARD_HEADER,
  method: POST,
};
exports.GET_SPREAD_RATE_CRYPTO = {
  url: GET_SPREAD_RATE_CRYPTO,
  method: POST,
};

exports.GET_DASHBOARD_CARD_HEADER = {
  url: GET_DASHBOARD_CARD_HEADER,
  method: POST,
};
exports.GET_DASHBOARD_CARD_HEADER_USER = {
  url: GET_DASHBOARD_CARD_HEADER_USER,
  method: POST,
}
exports.GET_DASHBOARD_USER_COUNT = {
  url: GET_DASHBOARD_USER_COUNT,
  method: POST,
};
exports.GET_BROWSER = {
  url: GET_BROWSER,
  method: POST,
};

exports.GET_LANGUAGE = {
  url: GET_LANGUAGE,
  method: POST,
};

exports.GET_DEVICE = {
  url: GET_DEVICE,
  method: POST,
};
exports.GET_KYT = {
  url: GET_KYT,
  method: POST,
}

exports.GET_CRYPTO_AMOUNT_EXCHANGE_RATE = {
  url: GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
  method: POST,
};

exports.UPDATE_WALLET_BALANCE = {
  url: UPDATE_WALLET_BALANCE,
  method: POST,
};

exports.GET_CRYPTOS_DETAILS = {
  url: GET_CRYPTOS_DETAILS,
  method: POST,
};

exports.UPDATE_GLOBAL_SETTING = {
  url: UPDATE_GLOBAL_SETTING,
  method: POST,
};

exports.UPDATE_B2C_USER_WALLET_BALANCE = {
  url: UPDATE_B2C_USER_WALLET_BALANCE,
  method: POST,
};

exports.CREATE_GLOBAL_SETTING = {
  url: CREATE_GLOBAL_SETTING,
  method: POST,
};

exports.UPDATE_WHITELIST_IP = {
  url: UPDATE_WHITELIST_IP,
  method: POST,
};

exports.UPDATE_CRYPTO = {
  url: UPDATE_CRYPTO,
  method: POST,
};

exports.CREATE_WHITELIST_IP = {
  url: CREATE_WHITELIST_IP,
  method: POST,
};
exports.CREATE_FRAUD_SERVICE = {
  url: CREATE_FRAUD_SERVICE,
  method: POST,
}
exports.GET_FRAUD_SERVICE = {
  url: GET_FRAUD_SERVICE,
  method: POST,
}
exports.UPDATE_FRAUD_SERVICE = {
  url: UPDATE_FRAUD_SERVICE,
  method: POST,
}
exports.GET_SINGLE_AQUIRER_SERVICE = {
  url: GET_SINGLE_AQUIRER_SERVICE,
  method: POST,
}
exports.UPDATE_CODE = {
  url: UPDATE_CODE,
  method: POST,
}
exports.ADD_NEW_BLOCKED_CARD={
  url:ADD_NEW_BLOCKED_CARD,
  method:POST
}
exports.ADD_NEW_BLOCKED_EMAIL={
  url:ADD_NEW_BLOCKED_EMAIL,
  method:POST
}
exports.DELETE_BLOCKED_CARD={
  url:DELETE_BLOCKED_CARD,
  method:POST
}
exports.DELETE_BLOCKED_EMAIL={
  url:DELETE_BLOCKED_EMAIL,
  method:POST
}

exports.DELETE_CODE = {
  url: DELETE_CODE,
  method: POST,
}
exports.UPDATE_AQUIRE_NAME = {
  url: UPDATE_AQUIRE_NAME,
  method: POST,
}
exports.TOGGLE_AQUIRER_SERVICE = {
  url: TOGGLE_AQUIRER_SERVICE,
  method: POST,
}
exports.TOGGLE_LOAD_BALANCING = {
  url: TOGGLE_LOAD_BALANCING,
  method: POST,
}
exports.ADD_INTEGRATION_TYPE = {
  url: ADD_INTEGRATION_TYPE,
  method: POST,
}
exports.GET_INTEGRATION_TYPE = {
  url: GET_INTEGRATION_TYPE,
  method: POST,
}
exports.DELETE_INTEGRATION_TYPE = {
  url: DELETE_INTEGRATION_TYPE,
  method: POST,
}
exports.EDIT_INTEGRATION_TYPE = {
  url: EDIT_INTEGRATION_TYPE,
  method: POST,
}
exports.UPDATE_SERVICE_NAME = {
  url: UPDATE_SERVICE_NAME,
  method: POST,
}
exports.DELETE_FRAUD_SERVICE = {
  url: DELETE_FRAUD_SERVICE,
  method: POST,
}

exports.CREATE_CRYPTO = {
  url: CREATE_CRYPTO,
  method: POST,
};

exports.GET_WALLETS = {
  url: GET_WALLETS,
  method: POST,
};
exports.GET_CENTRALISED_WALLET = {
  url: GET_CENTRALISED_WALLET,
  method: POST,
};
exports.UPDATE_CENTRALISED_WALLET_BALANCE = {
  url: UPDATE_CENTRALISED_WALLET_BALANCE,
  method: POST,
};


exports.VIEW_SINGLE_TRANSACTION = {
  url: VIEW_SINGLE_TRANSACTION,
  method: POST,
};

exports.VIEW_SINGLE_WALLET = {
  url: VIEW_SINGLE_WALLET,
  method: POST,
};
exports.VIEW_SINGLE_CENTRALISED_WALLET = {
  url: VIEW_SINGLE_CENTRALISED_WALLET,
  method: POST,
};
exports.UPDATE_CENTRALISED_WALLET_STATUS = {
  url: UPDATE_CENTRALISED_WALLET_STATUS,
  method: POST
}

exports.GET_TRANSFER_WALLETS = {
  url: GET_TRANSFER_WALLETS,
  method: POST,
};

exports.GET_SINGLE_CRYPTO_EXCHANGE_RATE = {
  url: GET_SINGLE_CRYPTO_EXCHANGE_RATE,
  method: POST,
};

exports.GET_MERCHANT_SETTING = {
  url: GET_MERCHANT_SETTING,
  method: POST,
};

exports.UPDATE_MERCHANT_SETTINGS = {
  url: UPDATE_MERCHANT_SETTINGS,
  method: POST,
};

exports.UPDATE_WALLET_STATUS = {
  url: UPDATE_WALLET_STATUS,
  method: POST,
};

exports.GET_ERROR_LOGS = {
  url: GET_ERROR_LOGS,
  method: POST,
};

exports.SAVE_TRANSACTION_FROM_ADMIN = {
  url: SAVE_TRANSACTION_FROM_ADMIN,
  method: POST,
};

exports.GET_SINGLE_TRANSFER_WALLET = {
  url: GET_SINGLE_TRANSFER_WALLET,
  method: POST,
};

exports.CREATE_DEPOSIT_TO_WALLET_ADMIN = {
  url: CREATE_DEPOSIT_TO_WALLET_ADMIN,
  method: POST,
};

exports.GET_ALL_B2C_USERS = {
  url: GET_ALL_B2C_USERS,
  method: POST,
};

exports.DELETE_B2C_USER = {
  url: DELETE_B2C_USER,
  method: POST,
};

exports.GET_B2C_USER_DETAILS = {
  url: GET_B2C_USER_DETAILS,
  method: POST,
};

exports.GET_B2C_WALLET_BALANCES = {
  url: GET_B2C_WALLET_BALANCES,
  method: POST,
};

exports.CREATE_VIP_LINK = {
  url: CREATE_VIP_LINK,
  method: POST,
};

exports.GET_B2C_USER_WALLETS = {
  url: GET_B2C_USER_WALLETS,
  method: POST,
};
exports.VIEW_B2C_SINGLE_USER_WALLET = {
  url: VIEW_B2C_SINGLE_USER_WALLET,
  method: POST,
};
exports.GET_B2C_USER_TRANSACTIONS = {
  url: GET_B2C_USER_TRANSACTIONS,
  method: POST,
};


exports.UPDATE_TRANSACTION = {
  url: UPDATE_TRANSACTION,
  method: POST,
};
exports.GET_ALL_BLOCKCHAIN_TRANSACTIONS = {
  url: GET_ALL_BLOCKCHAIN_TRANSACTIONS,
  method: POST,
};
exports.GET_ALL_HOUSE_KEEPING_ALERTS = {
  url: GET_ALL_HOUSE_KEEPING_ALERTS,
  method: POST,
};
exports.ADD_NOTES = {
  url: ADD_NOTES,
  method: POST,
};
exports.UPDATE_NOTES = {
  url: UPDATE_NOTES,
  method: POST,
}

exports.SEND_MERCHANT_WEBHOOK_NOTIFICATION_FROM_ADMIN = {
  url: SEND_MERCHANT_WEBHOOK_NOTIFICATION_FROM_ADMIN,
  method: POST,
};

exports.GET_TRANSACTIONS_SUMMARY = {
  url: GET_TRANSACTIONS_SUMMARY,
  method: POST,
};

exports.GET_SINGLE_B2C_USER_TRANSACTIONS = {
  url: GET_SINGLE_B2C_USER_TRANSACTIONS,
  method: POST,
};

exports.GET_REAL_TIME_EXCHANGE_RATES = {
  url: GET_REAL_TIME_EXCHANGE_RATES,
  method: POST
}

exports.GET_ALL_DAILY_EXCHANGE_RATES = {
  url: GET_ALL_DAILY_EXCHANGE_RATES,
  method: POST
}

exports.GET_ALL_BLOCKCHAIN_WALLET_BALANCES = {
  url: GET_ALL_BLOCKCHAIN_WALLET_BALANCES,
  method: POST
}

exports.GET_WALLETS_SUMMARY = {
  url: GET_WALLETS_SUMMARY,
  method: POST,
};
exports.GET_CENTRELISED_WALLETS_SUMMARY = {
  url: GET_CENTRELISED_WALLETS_SUMMARY,
  method: POST,
};

exports.ADD_USER_NOTES = {
  url: ADD_USER_NOTES,
  method: POST,
};

exports.GET_B2C_USER_GLOBAL_SETTING_WALLETS = {
  url: GET_B2C_USER_GLOBAL_SETTING_WALLETS,
  method: POST,
};

exports.UPDATE_B2C_USER_GLOBAL_SETTINGS_WALLET = {
  url: UPDATE_B2C_USER_GLOBAL_SETTINGS_WALLET,
  method: POST,
};

exports.DELETE_B2C_USER_GLOBAL_SETTING_WALLET = {
  url: DELETE_B2C_USER_GLOBAL_SETTING_WALLET,
  method: POST,
};

exports.CREATE_B2C_USER_GLOBAL_SETTING_WALLET = {
  url: CREATE_B2C_USER_GLOBAL_SETTING_WALLET,
  method: POST,
};

exports.CHANGE_ADMIN_PASSWORD = {
  url: CHANGE_ADMIN_PASSWORD,
  method: POST,
};

exports.MERCHANT_IMAGE_UPLOAD_SIGNED_URL = {
  url: MERCHANT_IMAGE_UPLOAD_SIGNED_URL,
  method: POST,
};

exports.GET_B2C_SPREAD_LEVEL = {
  url: GET_B2C_SPREAD_LEVEL,
  method: POST,
};

exports.UPDATE_B2C_SPREAD_LEVEL = {
  url: UPDATE_B2C_SPREAD_LEVEL,
  method: POST,
};

exports.CREATE_B2C_SPREAD_LEVEL = {
  url: CREATE_B2C_SPREAD_LEVEL,
  method: POST,
};

exports.DELETE_B2C_SPREAD_LEVEL = {
  url: DELETE_B2C_SPREAD_LEVEL,
  method: POST,
};

exports.USER_SUMMARY = {
  url: USER_SUMMARY,
  method: POST,
};

exports.UPDATE_B2C_USER_SPREAD_LEVEL = {
  url: UPDATE_B2C_USER_SPREAD_LEVEL,
  method: POST,
};


exports.GET_B2C_RISK_LEVEL = {
  url: GET_B2C_RISK_LEVEL,
  method: POST,
};

exports.UPDATE_B2C_RISK_LEVEL = {
  url: UPDATE_B2C_RISK_LEVEL,
  method: POST,
};

exports.CREATE_B2C_RISK_LEVEL = {
  url: CREATE_B2C_RISK_LEVEL,
  method: POST,
};

exports.DELETE_B2C_RISK_LEVEL = {
  url: DELETE_B2C_RISK_LEVEL,
  method: POST,
};

exports.UPDATE_B2C_USER = {
  url: UPDATE_B2C_USER,
  method: POST,
};

exports.ADD_UPDATE_USER_SPREAD_VALUE = {
  url: ADD_UPDATE_USER_SPREAD_VALUE,
  method: POST,
};

exports.GET_ALL_SPREAD_VALUE_USING_SPREAD_SETTING = {
  url: GET_ALL_SPREAD_VALUE_USING_SPREAD_SETTING,
  method: POST,
};

exports.UPDATE_B2C_USER_RISK_LEVEL = {
  url: UPDATE_B2C_USER_RISK_LEVEL,
  method: POST,
};

exports.GET_USER_TRANSFER_WALLETS = {
  url: GET_USER_TRANSFER_WALLETS,
  method: POST,
};

exports.CREATE_TRANSFER_WALLET = {
  url: CREATE_TRANSFER_WALLET,
  method: POST
}

exports.UPDATE_TRANSFER_WALLET = {
  url: UPDATE_TRANSFER_WALLET,
  method: POST
}

exports.DELETE_TRANSFER_WALLET = {
  url: DELETE_TRANSFER_WALLET,
  method: POST
}

exports.GET_MINIMUM_CONVERSION_AMOUNT = {
  url: GET_MINIMUM_CONVERSION_AMOUNT,
  method: POST,
};

exports.CREATE_MINIMUM_CONVERSION_AMOUNT = {
  url: CREATE_MINIMUM_CONVERSION_AMOUNT,
  method: POST,
};

exports.GET_RISK_LIVEL_LIMITS = {
  url: GET_RISK_LIVEL_LIMITS,
  method: POST,
};

exports.UPDATE_RISK_LIVEL_LIMITS = {
  url: UPDATE_RISK_LIVEL_LIMITS,
  method: POST,
};

exports.MAKE_WEBHOOKS_CALL = {
  url: MAKE_WEBHOOKS_CALL,
  method: POST,
};

exports.GET_MERCHANT_WALLETS = {
  url: GET_MERCHANT_WALLETS,
  method: POST,
};

exports.VIEW_SINGLE_MERCHANT_WALLET = {
  url: VIEW_SINGLE_MERCHANT_WALLET,
  method: POST,
};

exports.UPDATE_MERCHANT_WALLET_STATUS = {
  url: UPDATE_MERCHANT_WALLET_STATUS,
  method: POST,
};

exports.UPDATE_MERCHANT_WALLET_BALANCE = {
  url: UPDATE_MERCHANT_WALLET_BALANCE,
  method: POST,
};

exports.GET_MERCHANT_WALLETS_SUMMARY = {
  url: GET_MERCHANT_WALLETS_SUMMARY,
  method: POST,
};

exports.MERCHANT_TRANSACTIONS = {
  url: MERCHANT_TRANSACTIONS,
  method: POST,
}
exports.GET_MERCHANT_USERS = {
  url: GET_MERCHANT_USERS,
  method: POST,
}
exports.GET_INDIVIDUAL_MERCHANT_USER_DATA = {
  url: GET_INDIVIDUAL_MERCHANT_USER_DATA,
  method: POST
}
exports.UPDATE_INDIVIDUAL_MERCHANT_USER_DATA = {
  url: UPDATE_INDIVIDUAL_MERCHANT_USER_DATA,
  method: POST
}
exports.UPDATE_VIEW_ONLY_SETTING = {
  url: UPDATE_VIEW_ONLY_SETTING,
  method: POST,
}
exports.CREATE_NEW_MERCHANT_USER = {
  url: CREATE_NEW_MERCHANT_USER,
  method: POST,
}
exports.DELETE_MERCHANT_USER={
  url:DELETE_MERCHANT_USER,
  method:POST,
}
exports.GET_COUNTRY_DETAILS ={
  url: GET_COUNTRY_DETAILS,
  method: POST,
}
exports.VIEW_SINGLE_MERCHANT_TRANSACTION = {
  url: VIEW_SINGLE_MERCHANT_TRANSACTION,
  method: POST,
};


exports.GET_MERCHANT_TRANSACTIONS_SUMMARY = {
  url: GET_MERCHANT_TRANSACTIONS_SUMMARY,
  method: POST
}

exports.GET_ALL_MERCHANT_SPREAD = {
  url: GET_ALL_MERCHANT_SPREAD,
  method: POST,
};

exports.CREATE_MERCHANT_SPREAD = {
  url: CREATE_MERCHANT_SPREAD,
  method: POST,
};
exports.GET_ALL_SEND_RECEIVE = {
  url: GET_ALL_SEND_RECEIVE,
  method: POST,
};

exports.UPDATE_SEND_RECEIVE = {
  url: UPDATE_SEND_RECEIVE,
  method: POST,
};

exports.GET_MERCHANT_WALLET_AND_BALANCES = {
  url: GET_MERCHANT_WALLET_AND_BALANCES,
  method: POST
}

exports.CRYPTO_AMOUNT_SWAP = {
  url: CRYPTO_AMOUNT_SWAP,
  method: POST
}

exports.CREATE_MERCHANT_SWAP_TRANSACTION = {
  url: CREATE_MERCHANT_SWAP_TRANSACTION,
  method: POST
}

exports.CONFIRM_MERCHANT_SWAP_TRANSACTION = {
  url: CONFIRM_MERCHANT_SWAP_TRANSACTION,
  method: POST
}

exports.FETCH_GAS = {
  url: FETCH_GAS,
  method: POST
}

exports.CREATE_MERCHANT_WITHDRAW = {
  url: CREATE_MERCHANT_WITHDRAW,
  method: POST
}

exports.GET_MERCHANT_BALANCE = {
  url: GET_MERCHANT_BALANCE,
  method: POST
}

exports.ADMIN_DEPOSIT_WITHDRAW = {
  url: ADMIN_DEPOSIT_WITHDRAW,
  method: POST
}

exports.GET_TRANSACTION_DETAILS = {
  url: GET_TRANSACTION_DETAILS,
  method: POST
}

exports.GET_ALL_PAYMENT_PROVIDERS = {
  url: GET_ALL_PAYMENT_PROVIDERS,
  method: POST
}

exports.GET_PAYMENT_PROVIDER = {
  url: GET_PAYMENT_PROVIDER,
  method: POST
}

exports.CREATE_PAYMENT_PROVIDER = {
  url: CREATE_PAYMENT_PROVIDER,
  method: POST
}

exports.UPDATE_PAYMENT_PROVIDER = {
  url: UPDATE_PAYMENT_PROVIDER,
  method: POST
}

exports.GET_ALL_PAYMENT_SPREAD = {
  url: GET_ALL_PAYMENT_SPREAD,
  method: POST,
};
exports.CREATE_PAYMENT_SPREAD = {
  url: CREATE_PAYMENT_SPREAD,
  method: POST,
};

exports.SAVE_PAYMENT_PROVIDER_DOMAIN = {
  url: SAVE_PAYMENT_PROVIDER_DOMAIN,
  method: POST
}
exports.GET_PAYMENT_PROVIDER_SETTINGS = {
  url: GET_PAYMENT_PROVIDER_SETTINGS,
  method: POST
}
exports.UPDATE_PAYMENT_PROVIDER_SETTINGS = {
  url: UPDATE_PAYMENT_PROVIDER_SETTINGS,
  method: POST
}

exports.CREATE_CARD_COUNTRY_FEES={
  url:CREATE_CARD_COUNTRY_FEES,
  method:POST
}
exports.GET_CARD_COUNTRY_FEES={
  url:GET_CARD_COUNTRY_FEES,
  method:POST
}
exports.UPDATE_CARD_COUNTRY_FEES={
  url:UPDATE_CARD_COUNTRY_FEES,
  method:POST
}
exports.UPDATE_MERCHNAT_WALLET_BALANCE = {
  url: UPDATE_MERCHNAT_WALLET_BALANCE,
  method: POST
}
exports.GET_HOUSEKEEPING_ALERT = {
  url: GET_HOUSEKEEPING_ALERT,
  method: POST
}

exports.CREATE_UPDATE_MERCHANT_WALLET_AUTOMATION = {
  url: CREATE_UPDATE_MERCHANT_WALLET_AUTOMATION,
  method: POST
}

exports.GET_DEPOSIT_WITHDRAWAL_STATISTICS = {
  url: GET_DEPOSIT_WITHDRAWAL_STATISTICS,
  method: POST
}

exports.GET_UNIQUE_MERCHANT = {
  url: GET_UNIQUE_MERCHANT,
  method: POST
}
//sweep logs 
exports.GET_SWEEP_LOGS = {
  url: GET_SWEEP_LOGS,
  method: POST
}
exports.GET_DEPOSIT_HASH = {
  url: GET_DEPOSIT_HASH,
  method: POST
}
exports.GET_GETCOUNT_TABLE = {
  url: GET_GETCOUNT_TABLE,
  method: POST
}
exports.GET_MERCHANT_TRANSACTIONS_COUNT_DASHBOARD = {
  url: GET_MERCHANT_TRANSACTIONS_COUNT_DASHBOARD,
  method: POST
}

//acquire routing service
exports.GET_CARD_ACQUIRE_ROUTING = {
  url: GET_CARD_ACQUIRE_ROUTING,
  method: POST
}
exports.GET_AUDIT_TRAILS = {
  url: GET_AUDIT_TRAILS,
  method: POST
}

exports.UPDATE_MERCHANT_TRANSACTION = {
  url: UPDATE_MERCHANT_TRANSACTION,
  method: POST
}

exports.GET_ALL_CLIENTS = {
  url: GET_ALL_CLIENTS,
  method: POST
}

exports.CREATE_CLIENT_TRANS_CONFIG = {
  url: CREATE_CLIENT_TRANS_CONFIG,
  method: POST
}

exports.GET_ALL_CLIENT_TRANS_CONFIG = {
  url: GET_ALL_CLIENT_TRANS_CONFIG,
  method: POST
}

exports.UPDATE_CLIENT_TRANS_CONFIG = {
  url: UPDATE_CLIENT_TRANS_CONFIG,
  method: POST
}

exports.DELETE_CLIENT_TRANS_CONFIG = {
  url: DELETE_CLIENT_TRANS_CONFIG,
  method: POST
}

exports.CREATE_CLIENT_TRANSACTION = {
  url: CREATE_CLIENT_TRANSACTION,
  method: POST
}

exports.GET_CLIENT_TRANSACTIONS = {
  url: GET_CLIENT_TRANSACTIONS,
  method: POST
}

exports.GET_CLIENT_BALANCES = {
  url: GET_CLIENT_BALANCES,
  method: POST
}

exports.GET_ALL_CLIENT_TRANSACTIONS = {
  url: GET_ALL_CLIENT_TRANSACTIONS,
  method: POST
}

exports.GET_SINGLE_TRANSACTION_OF_CLIENT = {
  url: GET_SINGLE_TRANSACTION_OF_CLIENT,
  method: POST
}
exports.GET_PAYMENT_PROVIDER_SWEEP_CONFIG = {
  url: GET_PAYMENT_PROVIDER_SWEEP_CONFIG,
  method: POST
}
exports.CREATE_PAYMENT_PROVIDER_SWEEP_CONFIG = {
  url: CREATE_PAYMENT_PROVIDER_SWEEP_CONFIG,
  method: POST
}
exports.UPDATE_PAYMENT_PROVIDER_SWEEP_CONFIG = {
  url: UPDATE_PAYMENT_PROVIDER_SWEEP_CONFIG,
  method: POST
}
exports.DELETE_PAYMENT_PROVIDER_DOMAIN = {
  url: DELETE_PAYMENT_PROVIDER_DOMAIN,
  method: POST
}
exports.CREATE_PAYMENT_PROVIDER_USER = {
  url: CREATE_PAYMENT_PROVIDER_USER,
  method: POST
}
exports.GET_PAYMENT_PROVIDER_USER_LIST = {
  url: GET_PAYMENT_PROVIDER_USER_LIST,
  method: POST
}
exports.DELETE_PAYMENT_PROVIDER_USER = {
  url: DELETE_PAYMENT_PROVIDER_USER,
  method: POST
}
exports.UPDATE_PAYMENT_PROVIDER_USER = {
  url: UPDATE_PAYMENT_PROVIDER_USER,
  method: POST
}
exports.WHITE_LEBAL_PAYMENT_PROVIDER = {
  url: WHITE_LEBAL_PAYMENT_PROVIDER,
  method: POST
}
exports.GET_COUNTRY_CODE_FORM_DB = {
  url: GET_COUNTRY_CODE_FORM_DB,
  method: POST
}
exports.ADMIN_USER_VERIFY_2FA_TOKEN = {
  url: ADMIN_USER_VERIFY_2FA_TOKEN,
  method: POST
}
exports.ADMIN_GENERATE_QR_CODE = {
  url: ADMIN_GENERATE_QR_CODE,
  method: POST
}
exports.CREATE_MAX_TRAFFIC = {
  url : CREATE_MAX_TRAFFIC,
  method: POST
};
exports.GET_MAX_TRAFFIC_BY_ACQUIRER = {
  url : GET_MAX_TRAFFIC_BY_ACQUIRER,
  method: POST
};
exports.GET_MAX_TRAFFIC_STATUS = {
  url : GET_MAX_TRAFFIC_STATUS,
  method: POST
};
exports.RESET_MAX_TRAFFIC_STATUS = {
  url : RESET_MAX_TRAFFIC_STATUS,
  method: POST
};
exports.GET_SUCCESS_ROUTING_BY_ACQUIRER = {
  url : GET_SUCCESS_ROUTING_BY_ACQUIRER,
  method: POST
};
exports.GET_PERIOD_LIST_BY_ACQUIRER = {
  url : GET_PERIOD_LIST_BY_ACQUIRER,
  method: POST
};
exports.GET_LEAST_COST_ROUTING_BY_ACQUIRER = {
  url : GET_LEAST_COST_ROUTING_BY_ACQUIRER,
  method: POST
};
exports.UPDATE_LEAST_COST_ROUTING_BY_ACQUIRER = {
  url : UPDATE_LEAST_COST_ROUTING_BY_ACQUIRER,
  method: POST
};
exports.GET_WEIGHTING_BY_ACQUIRER = {
  url : GET_WEIGHTING_BY_ACQUIRER,
  method: POST
};
exports.UPDATE_WEIGHTING_BY_ACQUIRER = {
  url : UPDATE_WEIGHTING_BY_ACQUIRER,
  method: POST
};

exports.GET_FILTER_OPTIONS_CARD_DASHBOARD ={
  url: GET_FILTER_OPTIONS_CARD_DASHBOARD,
  method: POST
}
exports.GET_GRAPH_CARD_DASHBOARD_DATA = {
  url: GET_GRAPH_CARD_DASHBOARD_DATA,
  method: POST
}
exports.GET_CARD_TRANSACTIONS_SUMMARY = {
  url: GET_CARD_TRANSACTIONS_SUMMARY,
  method: POST
}
exports.GET_ERROR_DETAILS_CARD = {
  url: GET_ERROR_DETAILS_CARD,
  method: POST
}
exports.GET_CARD_TRANSACTION_DASHBOARD = {
  url: GET_CARD_TRANSACTION_DASHBOARD,
  method: POST
}

exports.UPDATE_CUSTOMCITY = {
  url: UPDATE_CUSTOMCITY,
  method: POST
}

exports.GET_RULES_BY_ACQUIRER_FOR_SPLITTRAFFIC = {
  url: GET_RULES_BY_ACQUIRER_FOR_SPLITTRAFFIC,
  method: POST
}

exports.UPDATE_SPLITTRAFFIC_BY_ACQUIRER = {
  url: UPDATE_SPLITTRAFFIC_BY_ACQUIRER,
  method: POST
}

exports.GET_TRAFFIC_MASKEDPAN_WHITELIST = {
  url: GET_TRAFFIC_MASKEDPAN_WHITELIST,
  method: POST
}
exports.UPLOAD_MERCHANT_IMAGE = {
  url: UPLOAD_MERCHANT_IMAGE,
  method: POST
}
exports.GET_ACQUIRER_DATA = {
  url: GET_ACQUIRER_DATA,
  method: POST,
};
exports.CREATE_NEW_ACQUIRER = {
  url: CREATE_NEW_ACQUIRER,
  method: POST,
};
exports.UPDATE_SINGLE_ACQUIRER = {
  url: UPDATE_SINGLE_ACQUIRER,
  method: POST,
};
exports.DELETE_SINGLE_ACQUIRER = {
  url: DELETE_SINGLE_ACQUIRER,
  method: POST,
};
exports.GET_SPLIT_TRAFFIC_FTD_ACTIVATION = {
  url: GET_SPLIT_TRAFFIC_FTD_ACTIVATION,
  method: POST,
};
exports.SPLIT_TRAFFIC_FTD_ACTIVATION = {
  url: SPLIT_TRAFFIC_FTD_ACTIVATION,
  method: POST,
};
exports.TOGGLE_BLOCKED_BIN_COUNTRIES_ACTIVATION = {
  url: TOGGLE_BLOCKED_BIN_COUNTRIES_ACTIVATION,
  method: POST,
};
exports.ACQUIRER_RULES_UPDATE_BLOCKED_BIN_COUNTRIES = {
  url: ACQUIRER_RULES_UPDATE_BLOCKED_BIN_COUNTRIES,
  method: POST,
};
exports.ACQUIRER_RULES_UPDATE_BLOCKED_USER_COUNTRIES = {
  url: ACQUIRER_RULES_UPDATE_BLOCKED_USER_COUNTRIES,
  method: POST,
};
exports.TOGGLE_BLOCKED_USER_COUNTRIES_ACTIVATION = {
  url: TOGGLE_BLOCKED_USER_COUNTRIES_ACTIVATION,
  method: POST,
};
exports.ACQUIRER_RULES_UPDATE_BLOCKED_CARD_SCHEMES = {
  url: ACQUIRER_RULES_UPDATE_BLOCKED_CARD_SCHEMES,
  method: POST,
};
exports.TOGGLE_ALLOWED_CURRENCIES_ACTIVATION = {
  url: TOGGLE_ALLOWED_CURRENCIES_ACTIVATION,
  method: POST,
};
exports.TOGGLE_BLOCKED_CARD_SCHEMES_ACTIVATION = {
  url: TOGGLE_BLOCKED_CARD_SCHEMES_ACTIVATION,
  method: POST,
};
exports.ACQUIRER_RULES_UPDATE_ALLOWED_CURRENCIES = {
  url: ACQUIRER_RULES_UPDATE_ALLOWED_CURRENCIES,
  method: POST,
};
exports.ACQUIRER_RULES_UPDATE_IP_ADRESSES = {
  url: ACQUIRER_RULES_UPDATE_IP_ADRESSES,
  method: POST,
};
exports.TOGGLE_MANDATORY_FIELDS_ACTIVATION = {
  url: TOGGLE_MANDATORY_FIELDS_ACTIVATION,
  method: POST,
};
exports.ACQUIRER_RULES_UPDATE_MANDATORY_FIELDS = {
  url: ACQUIRER_RULES_UPDATE_MANDATORY_FIELDS,
  method: POST,
};
exports.TOGGLE_ALLOWED_IP_ADDRESS_ACTIVATION = {
  url: TOGGLE_ALLOWED_IP_ADDRESS_ACTIVATION,
  method: POST,
};
exports.UPDATE_MAXIMUM_TRAFFIC_SETTING = {
  url: UPDATE_MAXIMUM_TRAFFIC_SETTING,
  method: POST,
};
exports.UPDATE_WEIGHTING_PERCENTAGE = {
  url: UPDATE_WEIGHTING_PERCENTAGE,
  method: POST,
};
exports.UPDATE_LEAST_COST_ROUTING = {
  url: UPDATE_LEAST_COST_ROUTING,
  method: POST,
};
exports.GET_LEAST_COST_ROUTING = {
  url: GET_LEAST_COST_ROUTING,
  method: POST,
};
exports.GET_WEIGHTING_PERCENTAGE = {
  url: GET_WEIGHTING_PERCENTAGE,
  method: POST,
};
exports.GET_MAXIMUM_TRAFFIC_SETTING = {
  url: GET_MAXIMUM_TRAFFIC_SETTING,
  method: POST,
};
exports.GET_GLOBAL_TRANSACTION_STATUS = {
  url: GET_GLOBAL_TRANSACTION_STATUS,
  method: POST,
};
exports.GET_GLOBAL_SUCCESS_ROUTING_BY_ACQUIRER = {
  url: GET_GLOBAL_SUCCESS_ROUTING_BY_ACQUIRER,
  method: POST,
};
exports.GET_GLOBAL_PERIOD_LIST_BY_ACQUIRER = {
  url: GET_GLOBAL_PERIOD_LIST_BY_ACQUIRER,
  method: POST,
};
exports.TOGGLE_CARD_AQUIRER_ACTIVATION = {
  url: TOGGLE_CARD_AQUIRER_ACTIVATION,
  method: POST,
};
exports.GET_CARD_AQUIRER_ROUTING_SERVICE_DATA = {
  url: GET_CARD_AQUIRER_ROUTING_SERVICE_DATA,
  method: POST,
};
exports.GET_SINGLE_CARD_AQUIRER_SERVICE = {
  url: GET_SINGLE_CARD_AQUIRER_SERVICE,
  method: POST,
};
exports.GET_CARD_AQUIRER_SERVICES = {
  url: GET_CARD_AQUIRER_SERVICES,
  method: POST,
};
exports.CREATE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW = {
  url: CREATE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW,
  method: POST,
};
exports.UPDATE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW = {
  url: UPDATE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW,
  method: POST,
};
exports.DELETE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW = {
  url: DELETE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW,
  method: POST,
};
exports.TOGGLE__BLOCKED__COUNTRIES__ACTIVATION = {
  url: TOGGLE__BLOCKED__COUNTRIES__ACTIVATION,
  method: POST,
};
exports.UPDATE_BLOCKED_COUNTRY_BY_SERVICE_LIST = {
  url: UPDATE_BLOCKED_COUNTRY_BY_SERVICE_LIST,
  method: POST,
};
exports.GET_BLOCKED_COUNTRY_BY_SERVICE_LIST = {
  url: GET_BLOCKED_COUNTRY_BY_SERVICE_LIST,
  method: POST,
};
exports.GET_LOAD_BALANCING = {
  url: GET_LOAD_BALANCING,
  method: POST,
};
exports.UPDATE_LOAD_BALANCING = {
  url: UPDATE_LOAD_BALANCING,
  method: POST,
};
exports.ADD_BLOCKED_CARD = {
  url: ADD_BLOCKED_CARD,
  method: POST,
};
exports.DELETE_BLOCKED_CARD_NEW = {
  url: DELETE_BLOCKED_CARD_NEW,
  method: POST,
};
exports.ADD_BLOCKED_EMAIL = {
  url: ADD_BLOCKED_EMAIL,
  method: POST,
};
exports.DELETE_BLOCKED_EMAIL_NEW = {
  url: DELETE_BLOCKED_EMAIL_NEW,
  method: POST,
};
exports.GET_AML_RULES = {
  url: GET_AML_RULES,
  method: POST,
};
exports.UPDATE_AML_RULES = {
  url: UPDATE_AML_RULES,
  method: POST,
};
exports.GET_BLOCKED_CARD_LIST = {
  url: GET_BLOCKED_CARD_LIST,
  method: POST,
};
exports.GET_BLOCKED_EMAIL_LIST = {
  url: GET_BLOCKED_EMAIL_LIST,
  method: POST,
};

