import React, { useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../../common/Loading";
const closeImage = "/cryptonpay/crytoicons/close.png";
import { call } from "../../../../../../config/axios";
import { ADD_BLOCKED_EMAIL } from "../../../../../../config/endpoints";
import localDb from "../../../../../../localDb";

function AddBlockedCard({
  handleCloseAddNew,
  emailList,
  uniqueId,
  setReload,
  reload,
  setValues,
}) {
  const baseURL = useSelector((state) => state.config.api_url);
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const [loading, setLoading] = useState(false);
  const [blockedEmail, setBlockedEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Please enter email that need to be blocked",
  );
  const baseUrl = useSelector((state) => state.config.api_url);
  const validation = (email) => {
    if (emailList.includes(email)) {
      setError(true);
      setErrorMsg("This email has been already blocked");
      return true;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setError(true);
      setErrorMsg("Please enter a valid EmailID");
      return true;
    }
  };
  const handleAddNewBlockedEmail = async () => {
    try {
      if (!blockedEmail || validation(blockedEmail)) {
        setError(true);
        return;
      } else {
        setLoading(true);
        const data = {
          email: localDb.getVal("email"),
          newEmail: blockedEmail,
          uniqueId,
        };
        const res = await call(
          {
            ...ADD_BLOCKED_EMAIL,
            url: baseURL + ADD_BLOCKED_EMAIL.url,
          },
          data,
        );

        if (res?.status === 200) {
          setLoading(false);
          setBlockedEmail("");
          setReload(reload);
          setError(false);
          handleCloseAddNew();
          setValues(res?.data?.routingService?.blockedList?.blockedEmailList);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div className="font-large font-weight-bold text-lg">{`Add Blocked Email`}</div>
        <div
          className="filter_close_button"
          onClick={() => {
            handleCloseAddNew();
          }}
        >
          <img src={`${imageBaseUrl}${closeImage}`} alt="closeImage" />
        </div>
      </div>
      <div className="upload__merchantId">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Blocked Email: </div>
          <input
            type="text"
            placeholder="Enter Blocked Email"
            value={blockedEmail}
            onChange={(e) => setBlockedEmail(e.target.value)}
          />
        </div>
      </div>
      {error && (
        <div className="my-2 w-full text-left text-red-600">{errorMsg}</div>
      )}
      <div className="d-flex justify-content-center pt-4">
        <button
          type="button"
          onClick={() => {
            handleAddNewBlockedEmail();
          }}
          className="btn btn-primary btn-md rounded-pill add-btn w-100"
        >
          {"Add"}
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default AddBlockedCard;
