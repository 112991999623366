import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { call } from "../../../../../../../config/axios";
import {
  GET_ALL_PAYMENT_PROVIDERS,
  GET_CARD_AQUIRER_SERVICES,
  CREATE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW,
  DELETE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW,
} from "../../../../../../../config/endpoints";
import localDb from "../../../../../../../localDb";
import HeaderAndFooterLayout from "../../../../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../../../../CommonPages/CommonHeader";
import AddCardAquireService from "./AddCardAquirerService";
import EditCardAquireService from "./EditCardAquireService";
import CardAquireServiceTable from "./cardAquirerServiceTable";
import DeletingAlert from "./DeletingAlert";
import Loading from "../../../../../../common/Loading";

const serviceHeaders = [
  {
    name: "Id",
    width: "15%",
  },
  {
    name: "Payment Provider",
    width: "20%",
  },
  {
    name: "Service Name",
    width: "20%",
  },
  {
    name: "FTD To Trusted Count",
    width: "15%",
  },
  {
    name: "Creation Date",
    width: "20%",
  },
  {
    name: "",
    width: "10%",
  },
];

const CardAquireService = () => {
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const [antiFraudServiceData, setAntiFraudServiceData] = useState([]);
  const [fraudServiceName, setFraudServiceName] = useState("");
  const [errorField, setErrorField] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [deletingAntiFraudServiceId, setDeletingAntiFraudServiceId] =
    useState(null);
  const [showAddNewAntiFraudModal, setShowAddNewAntiFraudModal] =
    useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editServiceData, setEditServiceData] = useState(null);
  const [paymentProviderList, setPaymentProviderList] = useState([]);
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState("");
  const email = localDb.getVal("email");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getAntiFraud();
  }, [deletingAntiFraudServiceId]);
  //Get Anti Fraud Service
  const getAntiFraud = async () => {
    setLoader(true);
    let payload = {
      email: localDb.getVal("email"),
    };
    await call(
      {
        ...GET_CARD_AQUIRER_SERVICES,
        url: base_url + GET_CARD_AQUIRER_SERVICES.url,
      },
      payload,
    )
      .then((res) => {
        setLoader(false);
        if (res?.status === 200) {
          setLoader(false);
          setAntiFraudServiceData(res?.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error("err in getAntiFraud", err);
      });
  };

  //Delete Anti
  const deleteAntiFraudService = async () => {
    setLoader(true);
    if (!deletingAntiFraudServiceId) {
      alert("No id selected");
    }
    let payload = {
      email: localDb.getVal("email"),
      uniqueId: deletingAntiFraudServiceId,
    };
    await call(
      {
        ...DELETE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW,
        url: base_url + DELETE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW.url,
      },
      payload,
    )
      .then((res) => {
        setLoader(false);
        if (res?.status === 200) {
          let newlist = antiFraudServiceData;
          newlist?.splice(
            newlist?.findIndex((a) => a?._id === deletingAntiFraudServiceId),
            1,
          );
          setAntiFraudServiceData(newlist);
          setDeletingAntiFraudServiceId(null);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  };
  const handleEditAntiFraudService = (data) => {
    setEditServiceData(data);
    setShowEditModal(true);
  };
  const validateFrom = () => {
    if (!fraudServiceName || fraudServiceName === "") {
      setErrorField("fraudServiceName");
      setErrorMessage("Service name is Required");
    }
  };
  const handleChange = (e) => {
    setFraudServiceName(e.target.value);
    if (e.target.value === "") {
      setErrorField("fraudServiceName");
      setErrorMessage("Service name is required");
    } else {
      setErrorField(null);
      setErrorMessage(null);
    }
  };

  const handleAddNewFraudService = () => {
    setLoader(true);
    validateFrom();
    if (errorField === null && errorMessage === null) {
      let timeout = null;
      timeout = setTimeout(async () => {
        if (errorField === null && errorMessage === null) {
          let payload = {
            email: localDb.getVal("email"),
            acquirerRoutingServiceName: fraudServiceName
              ? fraudServiceName
              : null,
            paymentProvider: selectedPaymentProvider
              ? selectedPaymentProvider
              : "",
          };
          await call(
            {
              ...CREATE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW,
              url:
                base_url + CREATE_SINGLE_CARD_AQUIRER_ROUTING_SERVICE_NEW.url,
            },
            payload,
          )
            .then((res) => {
              if (res?.status === 200) {
                setLoader(false);
                setShowAddNewAntiFraudModal(false);
                let newList = antiFraudServiceData;
                newList.push(res?.data);
                setAntiFraudServiceData(newList);
                setFraudServiceName("");
              }
            })
            .catch((err) => {
              setLoader(false);
              if (err?.response?.data?.err?.code === 11000) {
                setErrorMessage("Service name should be unique!");
                setErrorField("fraudServiceName");
              } else {
                setErrorMessage("Some thing went wrong..Please check");
                setErrorField("fraudServiceName");
              }
            });
        }
      });
    } else {
      setLoader(false);
      console.error(errorField, errorMessage);
    }
  };

  const getPaymentProviders = async (page) => {
    setLoader(true);
    const payload = {
      email,
      orderBy: "asc",
    };
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      payload,
    )
      .then((res) => {
        if (res?.status === 200) {
          setLoader(false);
          setPaymentProviderList(res?.data?.providerList);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err.message, "err");
      });
  };

  useEffect(() => {
    getPaymentProviders();
  }, []);
  return (
    <HeaderAndFooterLayout>
      <CommonHeader name={`Card Acquirer Routing Service`} backButton={true} />
      <div className="merchant-setting">
        <div className="routingServiceCofigMain">
          <CardAquireServiceTable
            serviceHeaders={serviceHeaders}
            paymentProviderList={paymentProviderList}
            antiFraudServiceData={antiFraudServiceData}
            setShowAddNewAntiFraudModal={setShowAddNewAntiFraudModal}
            deleteAntiFraud={(id) => {
              setDeletingAntiFraudServiceId(id);
            }}
            setEditModel={(val) => setShowAddNewAntiFraudModal(val)}
            handleEditAntiFraudService={handleEditAntiFraudService}
          />
        </div>
        {/* Add New Fraud Service */}
        <Dialog
          open={showAddNewAntiFraudModal}
          onClose={() => {
            setShowAddNewAntiFraudModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
        >
          <div>
            <AddCardAquireService
              handleClose={() => {
                setShowAddNewAntiFraudModal(false);
                setErrorField(null);
                setErrorMessage(null);
                setFraudServiceName("");
              }}
              paymentProviderList={paymentProviderList}
              setSelectedPaymentProvider={setSelectedPaymentProvider}
              selectedPaymentProvider={selectedPaymentProvider}
              handleChange={handleChange}
              handleAddNewFraudService={handleAddNewFraudService}
              fraudServiceName={fraudServiceName}
              setFraudServiceName={setFraudServiceName}
              errorField={errorField}
              setErrorField={setErrorField}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              antiFraudServiceData={antiFraudServiceData}
              setAntiFraudServiceData={(val) => setAntiFraudServiceData(val)}
            />
          </div>
        </Dialog>
        {/* Edit Fraud Service */}
        <Dialog
          open={showEditModal}
          onClose={() => {
            setEditServiceData(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
        >
          <EditCardAquireService
            handleClose={() => setShowEditModal(false)}
            handleDelete={(id) => {
              setDeletingAntiFraudServiceId(id);
            }}
            getAntiFraud={getAntiFraud}
            editServiceData={editServiceData}
            setEditServiceData={setEditServiceData}
            antiFraudServiceData={antiFraudServiceData}
            setAntiFraudServiceData={(val) => setAntiFraudServiceData(val)}
            paymentProviderList={paymentProviderList}
          />
        </Dialog>
        <DeletingAlert
          deletingId={deletingAntiFraudServiceId}
          onHide={() => setDeletingAntiFraudServiceId(null)}
          imageBaseUrl={imageBaseUrl}
          msg="Are you sure you want to delete selected setting? This action cannot be undone."
          onDeleteClickHandler={() => deleteAntiFraudService()}
        />
      </div>
      {loader && <Loading />}
    </HeaderAndFooterLayout>
  );
};

export default CardAquireService;
