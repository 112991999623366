import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import IOSSwitch from "./IOSSwitch";
import TabButton from "../../../../../common/TabButton";
import Loading from "../../../../../common/Loading";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import {
  GET_SPLIT_TRAFFIC_FTD_ACTIVATION,
  SPLIT_TRAFFIC_FTD_ACTIVATION,
} from "../../../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../../../config/axios";
import localDb from "../../../../../../localDb";

const SplitTrafficTab = ({ acquirerServiceData }) => {
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [acceptFTDValue, setAcceptFTDValue] = useState(false);
  const baseURL = useSelector((state) => state.config.api_url);
  const [acquirerId, setAcquirerId] = useState(null);

  const handleTabSwitch = (index, itemId = "") => {
    setAcquirerId(itemId);
    setTabs(index);
  };

  const onClickFTD = (e, item) => {
    setAcceptFTDValue(e);
    handleSave(e, item);
  };

  const handleSave = async (isActive, acquirerId) => {
    setLoading(true);
    const payload = {
      email: localDb.getVal("email"),
      acquirerUniqueId: acquirerId,
      isActive: isActive,
    };
    try {
      const res = await call(
        {
          ...SPLIT_TRAFFIC_FTD_ACTIVATION,
          url: baseURL + SPLIT_TRAFFIC_FTD_ACTIVATION.url,
        },
        payload,
      );

      if (res?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      logoutAfterError(error);
      setLoading(false);
      console.error("Error:", error);
    }
  };

  //Get SplitTraffic
  const getSplitTraffic = async () => {
    setLoading(true);
    try {
      const res = await call(
        {
          ...GET_SPLIT_TRAFFIC_FTD_ACTIVATION,
          url: baseURL + GET_SPLIT_TRAFFIC_FTD_ACTIVATION.url,
        },
        {
          email: localDb.getVal("email"),
          acquirerUniqueId: acquirerId,
        },
      );

      if (res?.status === 200) {
        setAcceptFTDValue(res?.data?.acceptFtd);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    getSplitTraffic();
  }, [tabs, acquirerServiceData]);

  if (!acquirerServiceData) {
    return <div className="w-full text-center">No Data</div>;
  }

  return (
    <div className="TabContainer">
      <div className="acquireServiceScroll">
        {acquirerServiceData?.map((item, index) => (
          <TabButton
            key={index}
            activeTab={tabs}
            handleTabSwitch={handleTabSwitch}
            tab={item?.acquirerSetting}
            index={index}
            itemId={item?.acquirerUniqueId}
          />
        ))}
      </div>
      <div className="ml-3 flex pt-10">
        <Grid container spacing={2}>
          {acquirerServiceData?.map((item, index) => (
            <div key={index}>
              {tabs === index && (
                <div className="mx-2 my-2 flex py-2">
                  <div className="mr-16 justify-start">Accept FTD</div>
                  <IOSSwitch
                    data-e2e="accept-ftd"
                    checked={acceptFTDValue}
                    onClick={
                      isViewPermissionValid
                        ? null
                        : (e) => {
                            onClickFTD(e?.target?.checked, item?.acquirerUniqueId);
                          }
                    }
                    className={`ml-20 ${isViewPermissionValid ? "disabled" : ""}`}
                  />
                </div>
              )}
            </div>
          ))}
        </Grid>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default SplitTrafficTab;
