import React, { useState } from "react";
import IOSSwitch from "../AcquirerConfig/IOSSwitch.js";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import { useSelector } from "react-redux";

import { UPDATE_LOAD_BALANCING } from "../../../../../../config/endpoints";
import localDb from "../../../../../../localDb";
import { call } from "../../../../../../config/axios";
import Loading from "../../../../../common/Loading";

const LoadBalance = ({ acquireService, setReload, reload, uniqueId }) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(false);
  const [maxTrafficFlag, setMaxTrafficFlag] = useState(
    acquireService?.loadBalancingActivation?.maxTrafficFlag || false,
  );
  const [successRoutingFlag, setSuccessRoutingFlag] = useState(
    acquireService?.loadBalancingActivation?.successRoutingFlag || false,
  );
  const [leaseCostRoutingFlag, setLeaseCostRoutingFlag] = useState(
    acquireService?.loadBalancingActivation?.leaseCostRoutingFlag || false,
  );
  const [weightingFlag, setWeightingFlag] = useState(
    acquireService?.loadBalancingActivation?.weightingFlag || false,
  );
  const [failoverFlag, setFailoverFlag] = useState(
    acquireService?.loadBalancingActivation?.failoverFlag || false,
  );
  const onClickMaxTraffic = (e) => {
    const newMaxTrafficFlag = e?.target?.checked;
    setMaxTrafficFlag(newMaxTrafficFlag);
    handletoggleLoadBalancing("maxTrafficFlag", newMaxTrafficFlag);
  };

  const onClickSuccessRouting = (e) => {
    const newSuccessRoutingFlag = e?.target?.checked;
    setSuccessRoutingFlag(newSuccessRoutingFlag);
    handletoggleLoadBalancing("successRoutingFlag", newSuccessRoutingFlag);
  };

  const onClickLeaseCostRoutingFlag = (e) => {
    const newLeaseCostRoutingFlag = e?.target?.checked;
    setLeaseCostRoutingFlag(newLeaseCostRoutingFlag);
    handletoggleLoadBalancing("leaseCostRoutingFlag", newLeaseCostRoutingFlag);
  };

  const onClickWeightingFlag = (e) => {
    const newWeightingFlag = e?.target?.checked;
    setWeightingFlag(newWeightingFlag);
    handletoggleLoadBalancing("weightingFlag", newWeightingFlag);
  };

  const onClickFailoverFlag = (e) => {
    const newFailoverFlag = e?.target?.checked;
    setFailoverFlag(newFailoverFlag);
    handletoggleLoadBalancing("failoverFlag", newFailoverFlag);
  };
  const handletoggleLoadBalancing = async (name, toggling) => {
    const payload = {
      maxTrafficFlag,
      successRoutingFlag,
      leaseCostRoutingFlag,
      weightingFlag,
      failoverFlag,
    };
    payload[name] = toggling;
    setLoading(true);
    const data = {
      email: localDb.getVal("email"),
      adminRole: "SuperAdmin",
      ...payload,
      uniqueId: uniqueId,
    };
    try {
      const res = await call(
        {
          ...UPDATE_LOAD_BALANCING,
          url: baseURL + UPDATE_LOAD_BALANCING.url,
        },
        data,
      );

      if (res?.status === 200) {
        setReload(!reload);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <div className="TabContainer">
      <div className="h-full bg-[#F9FAFB]">
        {loading && <Loading />}
        <h2 className="heading">Load Balancing</h2>
        <div className="ml-2 flex w-1/3 flex-col">
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Maximum Traffic</div>
            <IOSSwitch
              data-e2e="maximum-traffic"
              defaultChecked={maxTrafficFlag}
              onClick={(e) =>
                isViewPermissionValid ? null : onClickMaxTraffic(e)
              }
              className={`${isViewPermissionValid ? "disabled" : ""}`}
            />
          </div>
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Success Routing</div>
            <IOSSwitch
              data-e2e="success-routing"
              defaultChecked={successRoutingFlag}
              onClick={(e) =>
                isViewPermissionValid ? null : onClickSuccessRouting(e)
              }
              className={`${isViewPermissionValid ? "disabled" : ""}`}
            />
          </div>
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Least-Cost Routing</div>
            <IOSSwitch
              data-e2e="least-cost-routing"
              defaultChecked={leaseCostRoutingFlag}
              onClick={(e) =>
                isViewPermissionValid ? null : onClickLeaseCostRoutingFlag(e)
              }
              className={`${isViewPermissionValid ? "disabled" : ""}`}
            />
          </div>
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Weighting</div>
            <IOSSwitch
              data-e2e="weight"
              defaultChecked={weightingFlag}
              onClick={(e) =>
                isViewPermissionValid ? null : onClickWeightingFlag(e)
              }
              className={`${isViewPermissionValid ? "disabled" : ""}`}
            />
          </div>
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Failover</div>
            <IOSSwitch
              data-e2e="fail-over"
              defaultChecked={failoverFlag}
              onClick={(e) =>
                isViewPermissionValid ? null : onClickFailoverFlag(e)
              }
              className={`${isViewPermissionValid ? "disabled" : ""}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadBalance;
